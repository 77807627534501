import { RestRequester } from './restRequests';
import {
  AccountInfoData,
  ActivityStepData,
  AdminApi,
  AdminApiDisableAction,
  AdminDashboardData,
  AdminListRestParams,
  AuthData,
  AuthResponse,
  BaseResponseData,
  BasicSearchListRequestParams,
  BlockerDateSaveRequestData,
  ChangeAdminPasswordData,
  ChangePasswordData,
  CheckCodeData,
  CMSPageIds,
  CmsReportReasonResponse,
  CmsSizeResponse,
  CommonQuery,
  Cookies,
  CustomerAmbassadorForList,
  CustomerAmbassadorInstance,
  CustomerOffer,
  DashboardItemForEditing,
  ExtendedListRequestParams,
  GearMainInfoPart,
  GearResponse,
  ListResponseData,
  LocationData,
  LoginSocialData,
  MessagesRoomMessageRequest,
  MessagesRoomsRequest,
  OfferAction,
  OfferType,
  OrderActionType,
  OrderPurchaseInstance,
  OrderSellInstance,
  OrderType,
  ProductComment,
  ProductInstance,
  ProductRelated,
  ProfileApi,
  ProfileApiAddress,
  ProfileInfo,
  ProfileNotification,
  PurchaseApiType,
  PurchaseInstance,
  QueryParams,
  RegisterUserStep,
  ReportReasonOptions,
  ResetPasswordDate,
  VerifyPhoneNumberData,
  GearListRequest,
  CustomerGearResponse,
  ReviewsQuery,
  ReviewApi,
  CustomerReviewInstance,
  BasicPageListRequestParams,
  TransactionResponse,
  StripeAccountMethodsResponse,
  StripeLinkResponse,
  StripePayoutMethodResponse,
  PayoutProps,
  DashboardQueryParams,
  CancellationFeeData,
  AvailableDatesData,
  DealTypeForTab,
  BaseListRequestParams,
  CategoryTreeViewItem,
  VerifyPhoneNumberWithTokenData,
  CheckTokenAndCodeData,
  GearFilters,
  CustomerEventCounterData,
  ListResponseSanitizer,
  MenuResponse,
  GearSchedulePublicationPart,
  PurchaseCheckInstance,
  CustomerApi,
  NotificationsRequest,
  BlockUserRequest,
  ShortLinkResponse,
  TransactionDetailsResponse,
  QbpSyncType,
  LmSyncType,
  ExportType,
  GearListPaginatedRequest, StoreAddress,
} from 'types';
import { CACHE, DEFAULT_DASHBOARD_QUERY } from 'consts';
import { isBrowser } from 'utils/validators';
import { saveLogInDataOnBrowser } from 'utils/localStoreTools';
import { formatDate, getFormattedDate } from 'utils/formatters';
import { serverSideCacheWrapper } from 'utils/services';

// TRANSPORT TOOLS
const handleLogIn = (response: AuthResponse<any>) => {
  if (response.access_token && isBrowser()) {
    saveLogInDataOnBrowser(response.access_token);
  }
};

const formatRestResponse = (response: any): any => {
  const errorFormatter = (errors?: Record<string, string[]>): string[] => {
    if (!errors || !Object.keys(errors).length) return [];

    return Object.entries(errors || {}).map((error) => {
      return `${error[0]} field: ${error[1].join(' ')}`;
    });
  };

  return {
    ...response,
    ok: response.ok,
    data: response.data,
    statusCode: response.statusCode,
    meta: response.meta || null,
    links: response.links || null,
    message: response.error || response.message || null,
    errors: errorFormatter(response.errors),
  };
};

const safeRestResponseData = (response: BaseResponseData<any>, safeValue: any) => {
  if (response.ok) {
    return response.data;
  }
  return safeValue;
};

export const downloadFile = (blob: any, filename?: string) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  if (filename) {
    a.download = filename;
  }
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

export const getFileForAuthApi = async (url: string): Promise<Blob | null> => {
  return await RestRequester.getAuthFile(url);
};

// GENERAL
export const postData = (api: string, data: any) => {
  return RestRequester.postData(api, data);
}

// SHORT LINKS
export const getShortLinkUrl = async (hash: string) => {
  return RestRequester.getShortLinkUrl(hash);
};

// SITE DATA (CMS API)
export const getMenuData = async (force?: boolean): Promise<MenuResponse> => {
  return serverSideCacheWrapper({
    key: 'menuData',
    callback: async () => await RestRequester.getMenuData(),
    force,
  });
};

export const getPageData = async (id: CMSPageIds, force?: boolean) => {
  return serverSideCacheWrapper({
    key: id,
    callback: RestRequester.getPageData.bind(RestRequester),
    params: [id],
    force,
  });
};

export const getCustomPageData = async (url: string, force?: boolean) => {
  return serverSideCacheWrapper({
    key: url,
    callback: RestRequester.getCustomPageData.bind(RestRequester),
    params: [url],
    force,
  });
};

export const getCmsSizes = async (): Promise<CmsSizeResponse> => {
  return await RestRequester.getCmsSizes();
};

export const getBestBrands = async (limit: Number = 0, force?: boolean) => {
  return await serverSideCacheWrapper({
    key: 'bestBrands',
    callback: RestRequester.getBestBrands.bind(RestRequester),
    force,
  });
};

// CUSTOMER DATA
export const getCustomerData = async (api: CustomerApi, params: QueryParams = {}) => {
  return await RestRequester.getCustomerData(api, params);
};

export const getCustomerActivities = async (params: QueryParams = {}, force?: boolean) => {
  const response = await serverSideCacheWrapper({
    key: 'customerActivities',
    callback: RestRequester.getCustomerActivities.bind(RestRequester),
    params: [{ limit: 0, ...params }],
    force,
  });
  return safeRestResponseData(response, []);
};

export const getCustomerBrands = async (params: QueryParams = {}, force?: boolean) => {
  const response = await serverSideCacheWrapper({
    key: 'customerBrands',
    callback: RestRequester.getCustomerBrands.bind(RestRequester),
    params: [{ limit: 0, ...params }],
    force,
  });
  return safeRestResponseData(response, []);
};

export const getCustomerMarkets = async (params?: QueryParams, force?: boolean) => {
  const response = await serverSideCacheWrapper({
    key: 'customerMarkets',
    callback: RestRequester.getCustomerMarkets.bind(RestRequester),
    params: [{ limit: 0, ...params }],
    force,
  });
  return safeRestResponseData(response, []);
};

export const getCustomerCategories = async (params?: QueryParams, force?: boolean) => {
  const response = await serverSideCacheWrapper({
    key: 'customerCategories',
    callback: RestRequester.getCustomerCategories.bind(RestRequester),
    params: [{ limit: 0, ...params }],
    force,
  });
  return safeRestResponseData(response, []);
};

export const getCustomerCategoriesAsTree = async (
  params?: QueryParams,
  force?: boolean,
): Promise<CategoryTreeViewItem[]> => {
  const response = await serverSideCacheWrapper({
    key: 'customerCategoriesAsTree',
    callback: RestRequester.getCustomerCategoriesAsTree.bind(RestRequester),
    params: [{ limit: 0, ...params }],
    force,
  });
  return safeRestResponseData(response, []);
};

export const getCustomerColors = async (params?: QueryParams, force?: boolean) => {
  const response = await serverSideCacheWrapper({
    key: 'customerColors',
    callback: RestRequester.getCustomerColors.bind(RestRequester),
    params: [{ limit: 0, ...params }],
    force,
  });
  return safeRestResponseData(response, []);
};

export const getCustomerCategorySizes = async (id: number, params?: QueryParams) => {
  const response = await RestRequester.getCustomerCategorySizes(id, { limit: 0, ...params });
  return safeRestResponseData(response, []);
};

export const getItemManagementSettings = async () => {
  return await RestRequester.getItemManagementSettings();
};

export const getReportReasons = async (
  type: ReportReasonOptions,
  cookies?: Cookies,
): Promise<CmsReportReasonResponse> => {
  return await RestRequester.getReportReasons(type, cookies);
};

// CUSTOMER FAQ
export const getFaqTopics = async (force?: boolean) => {
  const response = await serverSideCacheWrapper({
    key: 'faqTopics',
    callback: RestRequester.getFaqTopics.bind(RestRequester),
    lifetime: CACHE.long,
    force,
  });
  return safeRestResponseData(response, []);
};

export const getFullFaq = async () => {
  const response = await RestRequester.getFullFaq();
  return safeRestResponseData(response, []);
};

export const getFaqTopicAnswers = async (id: number | string) => {
  const response = await RestRequester.getFaqTopicAnswers(id);
  return safeRestResponseData(response, []);
};

// CUSTOMER USER GUIDE
export const getUserGuideArticleList = async () => {
  const response = await RestRequester.getUserGuideArticleList();
  return safeRestResponseData(response, []);
};

export const getUserGuideArticleInstance = async (slug: string) => {
  const response = await RestRequester.getUserGuideArticleInstance(slug);
  return safeRestResponseData(response, []);
};

// CUSTOMER AMBASSADORS
export const getAmbassadorList = async (
  page?: number,
  sort?: string,
  order?: string,
): Promise<ListResponseData<CustomerAmbassadorForList[]>> => {
  const response = await RestRequester.getAmbassadorList(page, sort, order);
  return formatRestResponse(response);
};

export const getAmbassadorInstance = async (
  username: string,
): Promise<BaseResponseData<CustomerAmbassadorInstance>> => {
  const response = await RestRequester.getAmbassadorInstance(username);
  return safeRestResponseData(response, []);
};

// CUSTOMER GEAR
export const getGearFilters = async (params?: any) => {
  return await RestRequester.getGearFilters(params);
};

export const getGearList = async (params: GearListRequest = {}): Promise<GearResponse> => {
  return await RestRequester.getGearList(params);
};

export const getGearTags = async (params: GearListRequest = {}) => {
  return await RestRequester.getGearTags(params);
};

export const getGearInstance = async (slug: string, cookies?: Cookies): Promise<ProductInstance> => {
  const response = await RestRequester.getGearInstance(slug, cookies);
  return safeRestResponseData(response, {});
};

export const getGearRelated = async (slug: string): Promise<ProductRelated> => {
  const response = await RestRequester.getGearRelated(slug);
  return safeRestResponseData(response, []);
};

export const getGearLike = async (slug: string, params: GearListPaginatedRequest = {}): Promise<GearResponse> => {
  return await RestRequester.getGearLike(slug, params);
};

export const getGearLikeSizes = async (slug: string, params: GearListPaginatedRequest = {}): Promise<GearResponse> => {
  return await RestRequester.getGearLikeSizes(slug, params);
};

export const getGearSimilarBrands = async (slug: string, params: GearListPaginatedRequest = {}): Promise<GearResponse> => {
  return await RestRequester.getGearSimilarBrands(slug, params);
};

export const getTopSellers = async (params: GearListPaginatedRequest = {}): Promise<any> => {
  return await RestRequester.getTopSellers(params);
};

export const getPRPosts = async (): Promise<any> => {
  return await RestRequester.getPRPosts();
};

export const getExternalReviews = async (): Promise<any> => {
  return await RestRequester.getExternalReviews();
};

export const getGearComments = async (slug: string, page?: number): Promise<ListResponseData<ProductComment[]>> => {
  const response = await RestRequester.getGearComments(slug, page);
  return formatRestResponse(response);
};

export const postGearData = async (slug: string, api: string, data?: any): Promise<any> => {
  return await RestRequester.postGearData(slug, api, data);
};

export const getBalance = async (cookies?: Cookies): Promise<{ balance: number, currency: string }> => {
  return await RestRequester.getBalance(cookies);
};

export const getTransactions = async (
  query: BasicPageListRequestParams,
  cookies?: Cookies,
): Promise<TransactionResponse> => {
  return await RestRequester.getTransactions(query, cookies);
};
export const getTransactionDetails = async (
  id: string,
  cookies?: Cookies,
): Promise<TransactionDetailsResponse> => {
  return await RestRequester.getTransactionDetails(id, cookies);
};

// CUSTOMER CUSTOMERS DATA
export const getCustomerGearList = async (
  username: string,
  queryData: QueryParams = {},
): Promise<CustomerGearResponse> => {
  return RestRequester.getCustomerProfileDetails(username, 'listings', queryData);
};

export const getCustomerGearFilters = async (username: string, queryData: QueryParams = {}): Promise<GearFilters> => {
  const wrappedQueryData = queryData ? RestRequester.wrapQueryIntoBrackets('filters', queryData) : queryData;
  return RestRequester.getCustomerProfileDetails(username, 'listings/filters', wrappedQueryData);
};

export const customersSearch = async (queryData: BasicSearchListRequestParams) => {
  return await RestRequester.searchCustomers(queryData);
};

export const getCustomerProfile = async (username: string, cookies?: Cookies) => {
  return await RestRequester.getCustomerProfile(username, cookies);
};

export const getCustomerFollowers = async (username: string, page: number = 1) => {
  return await RestRequester.getCustomerProfileDetails(username, 'followers', { page });
};

export const getCustomerFollowing = async (username: string, page: number = 1) => {
  return await RestRequester.getCustomerProfileDetails(username, 'following', { page });
};

export const getCustomerShares = async (username: string, page: number = 1) => {
  return await RestRequester.getCustomerProfileDetails(username, 'shares', { page, limit: 18 });
};

export const postCommonCustomerData = async (api: string, data?: any): Promise<any> => {
  return await RestRequester.postCommonCustomerData(api, data);
};

export const postCustomerData = async (username: string, api: string, data?: any): Promise<any> => {
  return await RestRequester.postCustomerData(username, api, data);
};

// CUSTOMER REVIEWS
export const getReviews = async (
  query: ReviewsQuery,
  api: ReviewApi,
): Promise<ListResponseData<CustomerReviewInstance[]>> => {
  return await RestRequester.getReviews(query, api);
};

// CUSTOMER OFFERS
export const getCustomerOffers = async (
  type: OfferType,
  query?: CommonQuery,
  cookies?: Cookies,
): Promise<ListResponseData<CustomerOffer[]>> => {
  const response = await RestRequester.getCustomerOffers(type, query, cookies);
  return formatRestResponse(response);
};

export const postCustomerOffers = async (
  type: OfferType,
  id: number,
  action: OfferAction,
): Promise<BaseResponseData<CustomerOffer>> => {
  return await RestRequester.postCustomerOffers(type, id, action);
};

export const postCustomerCounterOffers = async (
  type: OfferType,
  id: number,
  action: OfferAction,
): Promise<BaseResponseData<CustomerOffer>> => {
  return await RestRequester.postCustomerCounterOffers(type, id, action);
};

export const createCounterOffer = async (
  requested_price: number,
  id: number,
): Promise<BaseResponseData<CustomerOffer>> => {
  return await RestRequester.createCounterOffers(requested_price, id);
};

// CUSTOMER PURCHASING
export const getPurchasing = async (id: number, cookies?: Cookies, guestToken?: string): Promise<BaseResponseData<PurchaseInstance>> => {
  return await RestRequester.getPurchasing(id, cookies, guestToken);
};

export const postPurchasing = async (
  id: string | number,
  api: PurchaseApiType,
  data?: any,
  cookies?: Cookies,
  guestToken?: string,
): Promise<BaseResponseData<PurchaseInstance>> => {
  return await RestRequester.postPurchasing(id, api, data, cookies, guestToken);
};

export const checkPurchasingItem = async (id: string | number, cookies?: Cookies, guestToken?: string): Promise<PurchaseCheckInstance> => {
  return await RestRequester.postCheckPurchasing(id, cookies, guestToken);
};

export const cancelPurchasing = async (itemId: number) => {
  return await RestRequester.cancelPurchasing(itemId);
};

// CUSTOMER ORDERS
export const getOrders = async (
  api: OrderType,
  query?: QueryParams,
  cookies?: Cookies,
): Promise<ListResponseData<OrderSellInstance[] | OrderPurchaseInstance[]>> => {
  return await RestRequester.getOrders(api, query, cookies);
};

export const getOrder = async (id: number, cookies?: Cookies) => {
  return await RestRequester.getOrder(id, cookies);
};

export const cancelSelling = async (orderId: number) => {
  return await RestRequester.postOrderAction(orderId, 'sell/cancel');
};

export const cancelRenting = async (orderId: number) => {
  return await RestRequester.postOrderAction(orderId, 'rent/cancel');
};

export const getRentingQR = async (orderId: number, type: string, cookies?: Cookies) => {
  return await RestRequester.postOrderAction(orderId, `rent/qr/${type}`, {}, cookies);
};

export const getRentingCode = async (orderId: number, type: string, cookies?: Cookies) => {
  return await RestRequester.postOrderAction(orderId, `rent/code/${type}`, {}, cookies);
};

export const setRentingPenalty = async (orderId: number, data: any, cookies?: Cookies) => {
  return await RestRequester.postOrderAction(orderId, `rent/penalty`, data, cookies);
};

export const sendRentingPhoto = async (orderId: string, type: string, data: any) => {
  return await RestRequester.postFormOrderAction(orderId, `rent/photos/${type}`, data);
};

export const deleteRentPhoto = async (mediaId: string) => {
  const response = await RestRequester.deleteRentMedia(mediaId);
  return formatRestResponse(response);
};

export const getRentAgreement = async (orderId: number) => {
  return await RestRequester.getOrderAction(orderId, `rent/agreement`);
};

export const getRentPenalty = async (orderId: number) => {
  return await RestRequester.getOrderAction(orderId, `rent/penalty`);
};

export const startRent = async (orderId: number, data: any) => {
  return await RestRequester.postOrderAction(orderId, `rent/start`, data);
};

export const signRent = async (orderId: number, data: any) => {
  return await RestRequester.postOrderAction(orderId, `rent/agreement`, data);
};

export const endRent = async (orderId: number, data: any) => {
  return await RestRequester.postOrderAction(orderId, `rent/end`, data);
};

export const postOrderAction = async (id: number, action: OrderActionType, data?: Record<string, any>) => {
  return await RestRequester.postOrderAction(id, action, data);
};

// CUSTOMER BLOG
export const getBlogPosts = async (page: number = 1, limit: number = 9, activities: string[] | string = '') => {
  return await RestRequester.getBlogPosts(page, limit, activities);
};

export const getBlogPost = async (slug: string) => {
  return await RestRequester.getBlogPost(slug);
};

export const subscribeToBlogPostActivity = async (email: string, slug: string) => {
  return await RestRequester.subscribeToBlogPostActivity(email, slug);
};

// AUTH DATA
export const signInFirstStep = async (data: AuthData) => {
  return await RestRequester.authFirstStep(data);
};

export const signInCheckCode = async (data: AuthData) => {
  const response = await RestRequester.authSecondStep(data);
  handleLogIn(response);
  return formatRestResponse(response);
};

export const getAuthUser = async (cookies: Cookies) => {
  const response = await RestRequester.authGetUser(cookies);
  return safeRestResponseData(response, null);
};

export const getRegisterUser = async (cookies: Cookies) => {
  const response = await RestRequester.getRegisteredUser(cookies);
  return safeRestResponseData(response, null);
};

export const registerUser = async (data: RegisterUserStep) => {
  const response = await RestRequester.registerUser(data);
  handleLogIn(response);
  return formatRestResponse(response);
};

export const acceptAgreement = async () => {
  const response = await RestRequester.acceptAgreement();
  return formatRestResponse(response);
};

export const verifyPhoneNumber = async (data: VerifyPhoneNumberData) => {
  const response = await RestRequester.passRegistrationStep('verify-phone/send', data);
  return formatRestResponse(response);
};

export const resetPhoneNumber = async (data: { email: string }) => {
  const response = await RestRequester.resetPhone(data);
  return formatRestResponse(response);
};

export const verifyPhoneNumberWithToken = async (data: VerifyPhoneNumberWithTokenData) => {
  return await RestRequester.verifyPhone(data);
};

export const verifyPhoneNumberWithTokenAndCode = async (data: CheckTokenAndCodeData) => {
  const response = await RestRequester.verifyPhoneCode(data);
  return formatRestResponse(response);
};

export const verifyCodeFromPhone = async <BaseResponseData>(data: CheckCodeData) => {
  const response = await RestRequester.passRegistrationStep('verify-phone', data);
  return formatRestResponse(response);
};

export const sendAccountTypeStepData = async (data: {account_type: string}) => {
  const response = await RestRequester.passAccountType(data);
  return formatRestResponse(response);
};

export const sendAccountInfoStepData = async (data: AccountInfoData) => {
  const response = await RestRequester.passRegistrationStep('account-info', data, true);
  return formatRestResponse(response);
};

export const sendLocationStepData = async (data: LocationData) => {
  const response = await RestRequester.passRegistrationStep('location', data);
  return formatRestResponse(response);
};

export const sendActivityStepData = async (data: ActivityStepData) => {
  const response = await RestRequester.passRegistrationStep('activities', data);
  return formatRestResponse(response);
};

export const changePassword = async (data: ChangePasswordData) => {
  const response = await RestRequester.authChangePassword(data);
  return formatRestResponse(response);
};

export const resetPassword = async (data: ResetPasswordDate) => {
  const response = await RestRequester.authResatPassword(data);
  return formatRestResponse(response);
};

export const authSocial = async (data: LoginSocialData) => {
  const response = await RestRequester.authSocial(data);
  handleLogIn(response);
  return response;
};

export const verifyEmail = async (id: string, email: string, expires: string, signature: string, cookies?: Cookies) => {
  return await RestRequester.verifyEmail(id, email, expires, signature, cookies);
};

export const resendVerifyEmail = async (email?: string) => {
  return await RestRequester.resendVerifyEmail(email);
};

// ADMIN PANEL / ADMINS
export const getAdmins = async (params: AdminListRestParams = {}, cookies?: Cookies): Promise<AdminDashboardData> => {
  const queryData: AdminListRestParams = {
    ...DEFAULT_DASHBOARD_QUERY,
    ...params,
  };
  return await RestRequester.getAdminApiList('admins', queryData, cookies);
};

// ADMIN PANEL / DASHBOARD
export const getDashboardStatistic = async (cookies?: Cookies) => {
  return RestRequester.getDashboardData('statistics', {}, cookies);
};

export const getTopUsers = async (queryParams?: DashboardQueryParams, cookies?: Cookies) => {
  return RestRequester.getDashboardData('users', queryParams, cookies);
};

export const getTopBrands = async (queryParams?: DashboardQueryParams, cookies?: Cookies) => {
  return RestRequester.getDashboardData('brands', queryParams, cookies);
};

// ADMIN PROFILE
export const changeAdminPhone = async (data: { phone: string }) => {
  return RestRequester.changeAdminAccountField(data, 'phone');
};

export const verifyAdminPhone = async (data: { code: string }) => {
  return RestRequester.verifyAdminPhone(data);
};

export const changeAdminPassword = async (data: ChangeAdminPasswordData) => {
  return RestRequester.changeAdminAccountField(data, 'password');
};

// ADMIN PANEL / EXPORTS
export const exportCustomers = async (blocked: boolean, isBlogSubscribers?: boolean) => {
  const filenamePrefix = blocked ? 'BlockedUsers' : isBlogSubscribers ? 'BlogSubscribers' : 'ActiveUsers';
  return exportTabItems(isBlogSubscribers ? 'blog-subscribers' : 'customers', { blocked }, filenamePrefix);
};

export const exportOrders = async (params: Record<string, any> = {}) => {
  return exportTabItems('orders', params, 'Orders');
};

export const exportTabItems = async (api: string, params: Record<string, any> = {}, filenamePrefix: string) => {
  const response = await RestRequester.exportCsvData(api, params);
  if (response.ok) {
    response
      .blob()
      .then((blob: any) => downloadFile(blob, `${filenamePrefix}_${getFormattedDate(Date.now(), 'MM/DD/YYYY')}.csv`));
    return null;
  } else {
    return response;
  }
};

export const exportAdminItems = async (filters?: QueryParams) => {
  const response = await RestRequester.exportGearCsvData(filters);
  if (response.ok) {
    response.blob().then((blob: any) => downloadFile(blob, `Items_${getFormattedDate(Date.now(), 'MM/DD/YYYY')}.csv`));
    return null;
  } else {
    return response;
  }
};

export const exportDashboardSalesReports = async (filters?: QueryParams) => {
  const response = await RestRequester.exportData('dashboard/reports/sales/export', filters);
  if (response.ok) {
    response.blob().then((blob: any) => downloadFile(blob, `SaleReports.csv`));
    return null;
  } else {
    return response;
  }
};

export const exportDashboardBalancesReports = async (filters?: QueryParams) => {
  const response = await RestRequester.exportData('dashboard/reports/user-balances/export', filters);
  if (response.ok) {
    response.blob().then((blob: any) => downloadFile(blob, `BalancesReports.csv`));
    return null;
  } else {
    return response;
  }
};

// ADMIN PANEL / PR POSTS
export const saveNewPrPostOrder = async (id: number, direction: 'up' | 'down') => {
  return await RestRequester.saveNewPrPostOrder(id, direction);
};

// ADMIN PANEL / ORDER DISPUTES
export const assignOrderDisputes = async (id: string | number) => {
  return await RestRequester.manageAdminApiOption('order-disputes', id, 'assign');
};

export const getOrderDisputeAdmins = async (options: BaseListRequestParams, cookies: Cookies) => {
  return await RestRequester.getAdminApiList('order-disputes/admins', options, cookies);
};

export const solveOrderDisputes = async (id: string | number, data: Record<'penalty', number>) => {
  return await RestRequester.manageAdminApiOption('order-disputes', id, 'solve', data);
};

export const getDisputeChatInfo = async (disputeId: string | number, interlocutorId: string, cookies?: Cookies) => {
  return RestRequester.getChatData(disputeId, interlocutorId, {}, cookies);
};

export const getDisputeChatMessages = async (
  disputeId: string | number,
  interlocutorId: string,
  queryParams?: QueryParams,
  cookies?: Cookies,
) => {
  return RestRequester.getChatData(disputeId, `${interlocutorId}/messages`, queryParams, cookies);
};

export const postDisputeChatMessage = async (disputeId: string | number, interlocutorId: string, data: any) => {
  return RestRequester.postChatData(disputeId, interlocutorId, data);
};

export const postDisputeChatMedia = async (
  disputeId: string | number,
  interlocutorId: string,
  data: any,
  isForm: boolean,
) => {
  return RestRequester.postChatData(disputeId, `${interlocutorId}/media/upload`, data, isForm);
};

// ADMIN PANEL / SUPPORT TICKETS
export const updateSupportTicketStatus = async (id: number, status: string) => {
  return await RestRequester.postSupportTicketStatus(id, status);
};

// ADMIN PANEL / SUPPORT TICKETS / CHAT
export const getSupportChatInfo = async (id: number | string, cookies?: Cookies) => {
  return await RestRequester.getAdminApiItem('support-tickets', `${id}/chat`, cookies);
};

export const getSupportChatMessages = async (id: number | string, options: QueryParams, cookies?: Cookies) => {
  return await RestRequester.getAdminApiItem('support-tickets', `${id}/chat/messages`, cookies, options);
};

export const getAdminMessagesChatInfo = async (id: string, options: QueryParams, cookies?: Cookies) => {
  return await RestRequester.getAdminApiItem('messages/rooms', id, cookies, options);
};

export const getAdminMessagesChatMessages = async (id: string, options: QueryParams, cookies?: Cookies) => {
  return await RestRequester.getAdminApiItem('messages/rooms', `${id}/messages`, cookies, options);
};

export const postSupportTicketChatMessage = async (id: string | number, data: any, isForm?: boolean) => {
  return await RestRequester.postSupportChatData(id, data, isForm);
};

// ADMIN PANEL / SIZES ORDER
export const saveNewSizesOrder = async (data: { sizes: Array<string | number> }) => {
  return await RestRequester.postAdminApiItemData('sizes/orders', data as any);
};

// ADMIN PANEL / USER GUIDE ORDER
export const saveNewUserGuideArticleOrder = async (id: number, direction: string) => {
  return await RestRequester.saveNewUserGuideArticleOrder(id, direction);
};

// ADMIN PANEL / ITEM MEDIA
export const adminUploadItemMedia = async (
  data: { media: File; order?: number; is_cover?: 1 | 0 },
  type: string,
  id: string,
  mediaId?: string,
) => {
  const response = await RestRequester.adminUploadItemMedia(data, type, id, mediaId);
  return formatRestResponse(response);
};

export const adminDeleteItemMedia = async (mediaId: string) => {
  const response = await RestRequester.adminDeleteItemMedia(mediaId);
  return formatRestResponse(response);
};

export const adminChangeItemMediaOrder = async (mediaId: string, order: number) => {
  const response = await RestRequester.adminChangeItemMediaOrder(mediaId, order);
  return formatRestResponse(response);
};

// ADMIN PANEL / AD CATALOG
export const adminAdCatalogFilesData = async (cookies?: Cookies) => {
  const response = await RestRequester.adminAdCatalogFilesData(cookies);
  return formatRestResponse(response);
};

// ADMIN PANEL / EXPORTS
export const adminStartExport = async (type: ExportType, data = {}) => {
  return await RestRequester.adminStartExport(type, data);
}

export const adminExportStatus = async (type: ExportType) => {
  return await RestRequester.adminExportStatus(type);
}

export const adminExportDownload = async (type: ExportType) => {
  const response = await RestRequester.adminExportDownload(type);

  if (response.ok) {
    let filename = `${type}_${getFormattedDate(Date.now(), 'MM/DD/YYYY')}.csv`;
    const contentDisposition = response.headers.get('Content-Disposition');

    if (contentDisposition) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      let matches = filenameRegex.exec(contentDisposition);
      if (matches && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }

    response.blob().then((blob: any) => downloadFile(blob, filename));
    return null;
  }

  return response;
}

// ADMIN PANEL / QBP
export const importQbpFile = async () => {
  return await RestRequester.importQbpFile();
};

export const importStatus = async () => {
  return await RestRequester.importStatus();
};
export const syncQbp = async (type: QbpSyncType, data = {}) => {
  return await RestRequester.syncQbp(type, data);
};

export const qbpSyncStatus = async (type: QbpSyncType) => {
  return await RestRequester.qbpSyncStatus(type);
};

export const qbpClearProducts = async () => {
  return await RestRequester.qbpClearProducts();
};

export const qbpCategoriesSync = async () => {
  return await RestRequester.qbpCategoriesSync();
};

// ADMIN PANEL / LM
export const lmImportFile = async (file: File, replace: boolean) => {
  return await RestRequester.lmImportFile({
    file,
    replace
  });
};

export const lmImportStatus = async () => {
  return await RestRequester.lmImportStatus();
};
export const lmSync = async (type: LmSyncType, data = {}) => {
  return await RestRequester.lmSync(type, data);
};

export const lmSyncStatus = async (type: LmSyncType) => {
  return await RestRequester.lmSyncStatus(type);
};

export const lmClearProducts = async () => {
  return await RestRequester.lmClearProducts();
};

export const lmMoveMedia = async () => {
  return await RestRequester.lmMoveMedia();
};

export const lmMoveMediaStatus = async () => {
  return await RestRequester.lmMoveMediaStatus();
};

// ADMIN PANEL / COMMON API
export const getAdminPanelBoard = async (
  api: AdminApi,
  query?: ExtendedListRequestParams,
  cookies?: Cookies,
  sanitizer?: ListResponseSanitizer,
) => {
  const filters = cookies?.parsed?.filters ? JSON.parse(cookies.parsed.filters) : query || {};
  const tab = cookies?.parsed?.tab;
  if (tab && !filters.type) {
    filters.type = tab;
  }
  const queryData: ExtendedListRequestParams = {
    ...DEFAULT_DASHBOARD_QUERY,
    ...filters,
    ...(query || {}),
  };
  const response = await RestRequester.getAdminApiList(api, queryData, cookies);
  if (sanitizer) {
    return sanitizer(response);
  }
  return response;
};

export const getAdminPanelUnit = async (api: AdminApi, id: string, cookies?: Cookies) => {
  const response = await RestRequester.getAdminApiItem(api, id, cookies);
  return safeRestResponseData(response, {});
};

export const getAdminUserAddresses = async (id: string, cookies?: Cookies) => {
  const response = await RestRequester.getAdminUserAddresses(id, cookies);
  return safeRestResponseData(response, []);
};

export const getAdminPanelCmsPage = async (api: AdminApi, id: string, cookies?: Cookies) => {
  return await RestRequester.getAdminApiItem(api, id, cookies);
};

export const postAdminPanelUnitData = async (
  api: AdminApi,
  data: DashboardItemForEditing,
  _?: any,
  withImage?: boolean,
) => {
  const response = withImage
    ? await RestRequester.postAdminApiItemWithImageData(api, data)
    : await RestRequester.postAdminApiItemData(api, data);

  return formatRestResponse(response);
};

export const updateAdminPanelUnit = async (
  api: AdminApi,
  data: DashboardItemForEditing,
  id: string,
  withImage?: boolean,
) => {
  const response = withImage
    ? await RestRequester.editAdminApiItemWithImage(api, data, id)
    : await RestRequester.editAdminApiItem(api, data, id);

  return formatRestResponse(response);
};

export const manageAdminApiOption = (
  api: AdminApi,
  id: string | number,
  option: string,
  data?: Record<string, any>,
) => {
  return RestRequester.manageAdminApiOption(api, id, option, data);
};

export const enableAdminPanelUnit = async (
  api: AdminApi,
  id: string,
  action: AdminApiDisableAction = 'hide',
): Promise<any> => {
  return RestRequester.disableAdminApiItem(api, action, id, action !== 'hide');
};

export const disableAdminPanelUnit = async (api: AdminApi, id: string, action: AdminApiDisableAction = 'hide') => {
  return RestRequester.disableAdminApiItem(api, action, id, action === 'hide');
};

export const deleteAdminPanelUnit = async (api: AdminApi, id: string, query?: QueryParams) => {
  return RestRequester.deleteAdminApiItem(api, id, query);
};

export const actionAdminPanelBulk = async (
  api: AdminApi,
  units: any[],
  action: string,
  params = {},
  field = 'items'
)=> {
  return RestRequester.put(`/admin/${api}/${action}/bulk`, {
    [field]: units,
    ...params,
  });
}

export const uploadMedia = async (apiUrl: string, data: Record<string, any>) => {
  return RestRequester.uploadMedia(apiUrl, data);
};

// PROFILE
export const getProfileData = async (api: ProfileApi, cookies?: Cookies, safeValue: any = {}) => {
  const response = await RestRequester.getProfileData(api, cookies);
  return safeRestResponseData(response, safeValue);
};

export const getStoresData = async (cookies?: Cookies, safeValue: any = {}) => {
  return await RestRequester.getStoresData(cookies);
};

export const getStoreData = async (id: number) => {
  return await RestRequester.getStoreData(id);
};

export const getStoreCalendarData = async (id: number, queryParams?: QueryParams) => {
  return await RestRequester.getStoreCalendarData(id, queryParams);
};

export const deleteStore = async (id: number) => {
  const response = await RestRequester.deleteStore(id);
  return formatRestResponse(response);
};

export const createStore = async (data: StoreAddress, _?: any) => {
  const response = await RestRequester.postStore(data);
  return formatRestResponse(response);
};

export const editStore = async (data: StoreAddress, id: number) => {
  const response = await RestRequester.updateStore(id, data);
  return formatRestResponse(response);
};

export const editStoreForm = async (data: StoreAddress, id: number) => {
  const response = await RestRequester.updateStoreForm(id, data);
  return formatRestResponse(response);
};

export const blockStoreTime = async (id: number, data: any) => {
  const response = await RestRequester.blockStoreTime(id, data);
  return formatRestResponse(response);
};
export const unblockStoreTime = async (id: number, data: any) => {
  const response = await RestRequester.unblockStoreTime(id, data);
  return formatRestResponse(response);
};

export const blockItemTime = async (id: number, data: any) => {
  const response = await RestRequester.blockItemTime(id, data);
  return formatRestResponse(response);
};

export const unblockItemTime = async (id: number, data: any) => {
  const response = await RestRequester.unblockItemTime(id, data);
  return formatRestResponse(response);
};0

export const getRawProfileData = async (api: ProfileApi, cookies?: Cookies, options?: QueryParams) => {
  return await RestRequester.getProfileData(api, cookies, options);
};

export const updateProfileInfo = async (data: ProfileInfo) => {
  const response = await RestRequester.postProfileInfo(data);
  return formatRestResponse(response);
};

export const updateProfileEmail = async (data: ProfileInfo) => {
  const response = await RestRequester.updateProfileData('email', data);
  return formatRestResponse(response);
};

export const updateProfilePhone = async (data: ProfileInfo) => {
  const response = await RestRequester.updateProfileData('phone', data);
  return formatRestResponse(response);
};

export const checkProfilePhoneCode = async (data: ProfileInfo) => {
  const response = await RestRequester.updateProfileData('phone/verify', data);
  return formatRestResponse(response);
};

export const updateProfilePassword = async (data: ProfileInfo) => {
  const response = await RestRequester.updateProfileData('password', data);
  return formatRestResponse(response);
};

export const connectSocial = (provider: string) => {
  if (typeof window === 'object') {
    location.assign(`${process.env.NEXT_PUBLIC_API_HOST}/profile/oauth/${provider}`);
  }
};

export const disconnectSocial = async (provider: string) => {
  const response = await RestRequester.socialDisconnectProfile(provider);
  return formatRestResponse(response);
};

export const checkSocialConnect = async (token: { token: string }) => {
  const response = await RestRequester.checkSocialConnect(token);
  return formatRestResponse(response);
};

export const createProfileAddress = async (data: ProfileApiAddress, _?: any) => {
  const response = await RestRequester.updateProfileData('addresses', data);
  return formatRestResponse(response);
};

export const editProfileAddress = async (data: ProfileApiAddress, id: string) => {
  const response = await RestRequester.editProfileData('addresses', id, data);
  return formatRestResponse(response);
};

export const deleteProfileAddress = async (id: string) => {
  const response = await RestRequester.deleteProfileData('addresses', id);
  return formatRestResponse(response);
};

export const setPrimaryAddress = async (id: string) => {
  const response = await RestRequester.updateProfileData(`addresses/${id}/primary`, {});
  return formatRestResponse(response);
};

export const subscribeProfileItem = async (api: ProfileApi, data: Record<string, number[]>) => {
  const response = await RestRequester.updateProfileData(api, data);
  return formatRestResponse(response);
};
export const unsubscribeProfileItem = async (api: ProfileApi, data: Record<string, number[]>) => {
  const response = await RestRequester.deleteProfileSubscription(api, data);
  return formatRestResponse(response);
};

export const updateProfileNotifications = async (data: ProfileNotification) => {
  const response = await RestRequester.updateProfileData(`notifications-settings/${data.type}/${data.settingName}`, {
    enabled: data.enabled,
  });
  return formatRestResponse(response);
};

export const updateProfileSizes = async (data: { sizes: Record<string, number> }) => {
  return await RestRequester.editProfileData('sizes', '', data);
};

export const deleteProfile = async () => {
  return await RestRequester.deleteProfile();
};

export const switchAccountType = async (data: {account_type: string, business_name?: string}) => {
  return await RestRequester.switchAccountType(data);
};

export const getIdentityVerificationData = async () => {
  return await RestRequester.getIdentityVerificationData();
};

export const createIdentityVerificationSession = async () => {
  return await RestRequester.createIdentityVerificationSession();
};

// profile payment specific methods
export const getStripePaymentIntent = async () => {
  return await RestRequester.getStripePaymentIntent();
};

export const saveStripePaymentMethod = async (data: { pm_id: string }) => {
  return await RestRequester.updateProfileData('payment-methods/stripe/methods', data);
};

export const setPrimaryPaymentMethod = async (id: string) => {
  return await RestRequester.updateProfileData(`payment-methods/stripe/methods/${id}/primary`, {});
};

export const deletePaymentMethod = async (id: string) => {
  return await RestRequester.deleteProfileData(`payment-methods/stripe/methods`, id);
};

export const createStripePayoutAcc = async (
  type: string = 'individual',
  cookies?: Cookies,
): Promise<StripeAccountMethodsResponse> => {
  return await RestRequester.postProfileData('payout-methods/stripe/account', { business_type: type }, {}, cookies);
};

export const getStripePayoutAccData = async (cookies?: Cookies): Promise<StripeAccountMethodsResponse> => {
  return await RestRequester.getProfileData('payout-methods/stripe/account', cookies);
};

export const getStripeRegistrationLink = async (cookies?: Cookies): Promise<StripeLinkResponse> => {
  return await RestRequester.postProfileData('payout-methods/stripe/account/link', {}, {}, cookies);
};

export const createStripePayoutMethod = async (
  token: string,
  primary: boolean,
  cookies?: Cookies,
): Promise<StripePayoutMethodResponse> => {
  return await RestRequester.postProfileData('payout-methods/stripe/methods', { token, primary }, {}, cookies);
};

export const setStripePrimaryMethod = async (id: string, cookies?: Cookies): Promise<StripePayoutMethodResponse> => {
  return await RestRequester.postProfileData(`payout-methods/stripe/methods/${id}/primary`, {}, {}, cookies);
};

export const deleteStripeMethod = async (id: string): Promise<StripePayoutMethodResponse> => {
  return await RestRequester.deleteProfileData('payout-methods/stripe/methods', id);
};

// CUSTOMER BALANCE
export const getCustomerBalance = async (cookies: Cookies) => {
  return await RestRequester.getCustomerBalance(cookies);
};

export const withdrawMoney = async (data: PayoutProps, cookies?: Cookies) => {
  return await RestRequester.postBalancePayout(data, cookies);
};

// CREATE / EDIT GEAR
export const getGearInfo = async (id?: string, cookies?: Cookies) => {
  const response = await RestRequester.getItemManagementData('items', cookies, id);
  return formatRestResponse(response);
};

export const getGearStockInfo = async (id: string, cookies?: Cookies) => {
  const response = await RestRequester.getItemStockData(id, cookies);
  return formatRestResponse(response);
};

export const putGearStockInfo = async (id: string, data: any, cookies?: Cookies) => {
  const response = await RestRequester.putItemStockData(id, data, cookies);
  return formatRestResponse(response);
};

export const getRentGearBlockedDates = async (id: string, cookies?: Cookies, to_date?: number) => {
  const fromDate = formatDate(new Date(), 'YYYY-MM-DD');
  const monthAndDay = fromDate.split('-').slice(1).join('-');
  const toDate = to_date || new Date().getFullYear() + 1 + '-' + monthAndDay;
  return await RestRequester.getItemManagementData('items', cookies, `${id}/unavailable-dates`, {
    from_date: fromDate,
    to_date: toDate,
  });
};

export const saveItemManagement = async (data: any, id?: string) => {
  const response = await RestRequester.saveItemManagementData('items', data, id);
  return formatRestResponse(response);
};

export const saveItemMainInfo = async (data: GearMainInfoPart, id?: string) => {
  const response = await RestRequester.saveItemManagementData('main-info', data, id);
  return formatRestResponse(response);
};

export const getCharityInfo = async () => {
  const response = await RestRequester.getCharityInfo();
  return formatRestResponse(response);
};

export const uploadItemMedia = async (
  data: { media: File; order?: number; is_cover?: 1 | 0 },
  type: string,
  id: string,
  mediaId?: string,
) => {
  const response = await RestRequester.uploadItemMedia(data, type, id, mediaId);
  return formatRestResponse(response);
};

export const deleteItemMedia = async (mediaId: string) => {
  const response = await RestRequester.deleteItemMedia(mediaId);
  return formatRestResponse(response);
};

export const changeItemMediaOrder = async (mediaId: string, order: number) => {
  const response = await RestRequester.changeItemMediaOrder(mediaId, order);
  return formatRestResponse(response);
};

export const passUploadItemMedia = async (id: string) => {
  const response = await RestRequester.saveItemManagementData('photo', {}, id);
  return formatRestResponse(response);
};

export const saveItemDescription = async (data: GearMainInfoPart, id?: string) => {
  const response = await RestRequester.saveItemManagementData('description', data, id);
  return formatRestResponse(response);
};

export const saveItemAdditionalInfo = async (data: GearMainInfoPart, id?: string) => {
  const response = await RestRequester.saveItemManagementData('additional-info', data, id);
  return formatRestResponse(response);
};

export const saveItemPricing = async (data: GearMainInfoPart, id?: string) => {
  const response = await RestRequester.saveItemManagementData('pricing', data, id);
  return formatRestResponse(response);
};

export const saveItemPackage = async (data: GearMainInfoPart, id?: string) => {
  const response = await RestRequester.saveItemManagementData('package', data, id);
  return formatRestResponse(response);
};

export const setItemPublishing = async (data: GearSchedulePublicationPart, id?: string) => {
  const response = await RestRequester.saveItemManagementData('schedule-publish', data, id);
  return formatRestResponse(response);
};

export const saveBlockedRentDates = async (data: BlockerDateSaveRequestData, id: string) => {
  const response = await RestRequester.saveItemManagementData(`items`, data, `${id}/blocked-rent-dates`);
  return formatRestResponse(response);
};

export const saveNewItemData = async (data: any, id?: string) => {
  const response = await RestRequester.saveItemManagementData(`items`, data, id);
  return formatRestResponse(response);
};

export const passAvailableRentDates = async (id?: string, data?: AvailableDatesData) => {
  if (!id) return {};
  const wrappedData = {
    meeting: { ...data },
  };
  const response = await RestRequester.saveItemManagementData('available-dates', wrappedData, id);
  return formatRestResponse(response);
};

export const saveCancellationFee = async (data: CancellationFeeData, id: string) => {
  if (typeof data.rental_agreement === 'undefined') {
    data.remove_rental_agreement = '1';
  }
  if (typeof data.rental_agreement === 'string') {
    data.rental_agreement = null;
  }
  const response = await RestRequester.uploadRentalAgreement(data, id);
  return formatRestResponse(response);
};

export const deleteItem = async (id: string) => {
  return await RestRequester.deleteItem(id);
};
export const archiveItem = async (id: string) => {
  return await RestRequester.archiveItem(id);
};

// GEAR LIST
export const getCustomerGear = async (query: QueryParams, cookies?: Cookies) => {
  return await RestRequester.getItemManagementData('items', cookies, '', query);
};

export const getCustomerShippingDimensions = async (force?: boolean, cookies?: Cookies) => {
  const response = await serverSideCacheWrapper({
    key: 'shippingDimensions',
    callback: RestRequester.getItemManagementData.bind(RestRequester),
    params: ['shipping-dimensions', cookies],
    force,
  });
  return safeRestResponseData(response, []);
};

// SEARCH
export const categoriesSearch = async (q: string) => {
  return await RestRequester.categoriesSearch(q);
};

// BLACKLIST
export const blockUser = async (username: string, data: BlockUserRequest) => {
  return await RestRequester.blockUser(username, data);
};

// NOTIFICATIONS
export const getNotifications = async (data: NotificationsRequest) => {
  return await RestRequester.getNotifications(data);
};

export const setReadNotifications = async (id: string, data?: {seen: boolean}) => {
  return await RestRequester.setReadNotifications(id, data);
};

// MESSAGE ROOM
export const getMessagesRooms = async (data: MessagesRoomsRequest, cookies?: Cookies) => {
  return await RestRequester.getMessagesRooms(data, cookies);
};

export const messagesRoom = async (id: string, cookies?: Cookies) => {
  return await RestRequester.getMessagesRoom(id, cookies);
};

export const messagesRoomWithUser = async (userId: string, cookies?: Cookies) => {
  return await RestRequester.getMessagesRoomWithUser(userId, cookies);
};

export const createMessagesRoomWithUser = async (userId: string, cookies?: Cookies) => {
  return await RestRequester.postMessagesRoomWithUser(userId, cookies);
};

export const messagesRoomWithItem = async (itemId: string, buyer_id?: string, cookies?: Cookies) => {
  return await RestRequester.getMessagesRoomWithItem(itemId, buyer_id, cookies);
};

export const createMessagesRoomWithItem = async (itemId: string, cookies?: Cookies) => {
  return await RestRequester.postMessagesRoomWithItem(itemId, cookies);
};

export const createMessagesRoomWithSupport = async (cookies?: Cookies) => {
  return await RestRequester.postMessagesRoomWithSupport(cookies);
};

export const archiveMessagesRoom = async (id: string, archived: boolean, cookies?: Cookies) => {
  return await RestRequester.saveMessagesRoomInArchive(id, archived, cookies);
};

export const messagesRoomMessages = async (id: string, page: number, fromTime?: number, cookies?: Cookies) => {
  return await RestRequester.getMessagesRoomMessages(id, page, fromTime, cookies);
};

export const sendMeesagesRoomMessage = async (id: string, data: MessagesRoomMessageRequest, cookies?: Cookies) => {
  return await RestRequester.postMessagesRoomMessage(id, data, cookies);
};

export const uploadMessagesRoomMedia = async (roomId: string, media: File, cookies?: Cookies) => {
  return await RestRequester.uploadMessagesRoomMedia(roomId, media, cookies);
};

export const readMessagesRoomMessages = async (id: string, cookies?: Cookies) => {
  return await RestRequester.readMessagesRoomMessages(id, cookies);
};

// FEED
export const getFeed = async (
  page: number = 1,
  fromTime: number | null = null,
  tab?: DealTypeForTab,
  cookies?: Cookies,
) => {
  return await RestRequester.feed(page, fromTime, tab, cookies);
};

export const viewFeedEvents = async (events: string[], cookies?: Cookies) => {
  return await RestRequester.viewFeedEvents(events, cookies);
};

export const getFeedAdditionalData = async (cookies?: Cookies) => {
  return await RestRequester.feedAdditionalData(cookies);
};

// EVENTS
export const getEventCounters = async (cookies?: Cookies): Promise<CustomerEventCounterData> => {
  return await RestRequester.getEventCounters(cookies);
};

export const postContactFormData = async (data: any) => {
  return await RestRequester.postContactFormData(data);
};
