import { default as jsCookies } from 'js-cookie';
import * as Sentry from '@sentry/nextjs';
import { Cookies, CustomHeaders, Params, QueryParams } from 'types';
import { isBrowser } from 'utils/validators';
import { obj2FromData } from 'utils/formDataFormatter';
import { removeArrayIndexFromStringIfNeeded } from 'utils/formatters';
// import { getFormattedServersideErrors } from 'utils/services';
import { TOKEN_PROTECTED_URLS } from 'consts';

export class BaseRestRequester {
  constructor() {}

  private static readonly timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  public static async getAuthFile(url: string) {
    return fetch(url, this.updateParams({}))
      .then((response) => response.blob())
      .then((blob) => {
        return blob;
      })
      .catch((error) => {
        console.error(error);
        return null;
      });
  }

  static get(url: string, queryData?: QueryParams, params: Params = {}, cookies: Cookies = {}, useBaseApiUrl = true) {
    if (TOKEN_PROTECTED_URLS.includes(url) && !this.checkAccessToken(cookies)) {
      return {
        ok: false,
        data: [],
        message: 'Unauthorized request attempt blocked.',
        error: `You have to be authorized for request to ${url}.`,
      };
    }

    return this.send(
      url + this.composeQueryParams(queryData),
      {
        method: 'GET',
        ...params,
      },
      cookies,
      'json',
      useBaseApiUrl
    );
  }

  static post<T>(url: string, body?: unknown, params: Params = {}, cookies?: Cookies, format?: string) {
    return this.send<T>(
      url,
      {
        ...this.getDefaultParams('POST', body),
        ...params,
      },
      cookies,
      format,
    );
  }

  static postForm<T>(url: string, body?: unknown, params: Params = {}, cookies?: Cookies) {
    return this.send<T>(
      url,
      {
        ...this.getFormParams('POST', body),
        ...params,
      },
      cookies,
    );
  }

  static put<T>(url: string, body?: unknown, params: Params = {}, cookies?: Cookies) {
    return this.send<T>(
      url,
      {
        ...this.getDefaultParams('PUT', body),
        ...params,
      },
      cookies,
    );
  }

  static putForm<T>(url: string, body?: unknown, params: Params = {}, cookies?: Cookies) {
    return this.send<T>(
      url,
      {
        ...this.getFormParams('PUT', body),
        ...params,
      },
      cookies,
    );
  }

  static delete(
    url: string,
    queryData?: Record<string, any>,
    params: Params = {},
    cookies?: Cookies,
    body?: Record<string, any>,
  ) {
    return this.send(
      url + this.composeQueryParams(queryData),
      {
        ...this.getDefaultParams('DELETE', body),
        ...params,
      },
      cookies,
    );
  }

  private static getFormParams(method: string, body?: any) {
    const formData = obj2FromData(body);

    return {
      method: method,
      body: formData,
    };
  }

  private static getDefaultParams(method: string, body?: any) {
    return {
      method: method,
      body: JSON.stringify(body),
      headers: {
        'Content-type': 'application/json',
      },
    };
  }

  private static checkAccessToken(cookies?: Cookies) {
    return (
      !!cookies?.access_token ||
      !!cookies?.parsed?.['access_token'] ||
      (typeof window === 'object' && !!window.localStorage.getItem('access_token'))
    );
  }

  private static getAccessToken(cookies?: Cookies) {
    if (cookies?.access_token) {
      return cookies?.access_token;
    }
    if (cookies) {
      return cookies.parsed?.['access_token'] || jsCookies.get('access_token');
    }
    if (isBrowser()) {
      const token = window.localStorage.getItem('access_token');
      return token ? JSON.parse(token) : null;
    }
  }

  // private static changeCursor(cursor: 'wait' | 'default' = 'default') {
  //   if (typeof window === 'object') {
  //     document.body.style.cursor = cursor;
  //   }
  // }

  private static updateParams(params: Params = {}, cookies?: Cookies): Params {
    const defaultHeaders: HeadersInit & CustomHeaders = {};

    // browser headers
    if (isBrowser()) {
      defaultHeaders['X-Timezone'] = this.timeZone;
    }
    // server headers
    else {
      defaultHeaders['X-Original-SSR'] = '1';
    }

    if (!params.noAccessToken) {
      const accessToken = this.getAccessToken(cookies);
      if (accessToken) {
        defaultHeaders.Authorization = `Bearer ${accessToken}`;
      }
    }

    return {
      ...params,
      headers: {
        ...defaultHeaders,
        ...(params.headers || {}),
      },
    };
  }

  private static async send<T>(url: string, params: Params = {}, cookies?: Cookies, format: string = 'json', useBaseApiUrl = true) {
    if (typeof window === 'object') {
      document.body.style.cursor = 'wait';
    }
    const updatedParams = this.updateParams(params, cookies);
    const baseUrl = useBaseApiUrl
      ? process.env.NEXT_PUBLIC_API_BASE_URL
      : process.env.NEXT_PUBLIC_API_HOST;

    return fetch(`${baseUrl}${url}`, updatedParams)
      .then(async (response) => {
        if (typeof window === 'object') {
          document.body.style.cursor = 'default';
        }
        if (response.ok) {
          if (format === 'json' && response.status !== 204) {
            try {
              const parsed = { ...(await response.json()), statusCode: response.status, ok: response.ok, guestToken: response.headers.get('X-Set-Order-Token') };
              return parsed as T;
            } catch (error) {
              console.warn('response.json() parsing error: ', error);
              // Sentry.captureException({ error, errorDescription: 'response.json() parsing error' });
              return response;
            }
          }

          return response;
        }

        const formattedErrorResponse = await this.handleErrorResponse(response);
        // const { header, message } = getFormattedServersideErrors(formattedErrorResponse);
        // Sentry.captureException(`[FETCH EXCEPTION (${process.env.NODE_ENV})] :: ${header} :: ${message}`);
        return formattedErrorResponse;
      })
      .catch((err) => {
        if (typeof window === 'object') {
          document.body.style.cursor = 'default';
        }
        Sentry.captureException(err);
        return { statusCode: err.status, ok: false };
      });
  }

  private static async handleErrorResponse(response: Response) {
    return { ...(await response.json()), data: [], statusCode: response.status, ok: response.ok };
  }

  public static composeQueryParams(queryData: Record<string, any> = {}, prefix: string = '') {
    const params: string[] = [];

    Object.entries(queryData).forEach(([key, value]) => {
      key = prefix ? `${prefix}[${key}]` : key;

      if (value === undefined || value === null) {
        value = '';
      }

      params.push(
        typeof value === 'object'
          ? this.composeQueryParams(value, key)
          : `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      );
    });

    return params.length && !prefix ? `?${params.join('&')}` : params.join('&');
  }

  public static wrapQueryIntoBrackets(wrapperSlug: string, content: Record<string, QueryParams>) {
    return Object.entries(content).reduce((acc: Record<string, QueryParams>, entry) => {
      if (Array.isArray(entry[1])) {
        const accumulatedValue = entry[1].reduce((innerAcc, value, index) => {
          innerAcc[`${wrapperSlug}[${removeArrayIndexFromStringIfNeeded(entry[0])}][${index}]`] = value;
          return innerAcc;
        }, {});
        return { ...acc, ...accumulatedValue };
      } else {
        acc[`${wrapperSlug}[${removeArrayIndexFromStringIfNeeded(entry[0])}]`] = entry[1];
      }
      return acc;
    }, {});
  }
}
