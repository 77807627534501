import { Dispatch, MutableRefObject } from 'react';
import cookie from 'cookie';
import { ParsedUrlQuery } from 'querystring';
import { IncomingMessage } from 'http';
import {
  Cookies,
  CountDownArgs,
  CurrentUser,
  CustomerProfile,
  DropdownOption,
  DropdownOptionWithChildren,
  ErrorResponse,
  GearInstance,
  iconicImagesShape,
  ItemManagementApi,
  JoinedFormFieldProps,
  MessagesMessage,
  MessagesRoomType,
  OrderStatus,
  SimpleItem,
  UnavailableTimes,
} from 'types';
import {
  GEAR_CREATION_BASE_STEP_CAPTIONS,
  GEAR_CREATION_STEP_CAPTION_MAP,
  GEAR_CREATION_STEPS,
  SIGN_UP_STEP_CAPTION_MAP,
  SIGN_UP_STEP_CAPTIONS,
  HOSTNAMES,
  SYSTEM_CHAT_TYPES,
  GEAR_OPTIONS_CREATION_STEP_CAPTION_MAP,
  RENT_GEAR_CREATION_STEP_CAPTION_MAP,
} from 'consts';
import { authSocial, getCustomerColors, getCustomerShippingDimensions } from 'api/transport';
import { getCustomerActivities, getCustomerBrands, getCustomerCategories, getCustomerMarkets, postGearData } from 'api';
import {
  composeServerErrorForSnackbar,
  formatDate,
  getLastMonthFirstLastDays,
  getLastWeekFirstLastDays,
  getThisMonthFirstLastDays,
  getThisWeekFirstLastDays,
} from './formatters';
import { SnackbarProps } from 'components/Snackbar';
import { SpecificCalendarRangeProps } from 'components/DateTimePickers/CalendarRentRangePicker';
import {
  DashboardDateFilterOptionId,
  DashboardDateFilterOptions,
} from 'containers/AdminPanel/Dashboard/SalesReportPanelFilters';
import { saveSessionError, trackSessionRedirects } from './localStoreTools';
import { CachePickDataArgs } from '../api/requestsCacheProvider';
import { fi } from 'date-fns/locale';

export const getCookies = (req?: IncomingMessage) => ({ ...cookie.parse(req?.headers?.cookie || '') });

export type IconicImageProps = {
  width: number;
  height: number;
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
};

export const getIconicImageProps = (shape: iconicImagesShape): IconicImageProps => {
  if (shape === 'icon') {
    return {
      width: 24,
      height: 24,
    };
  }

  return {
    width: 40,
    height: 40,
    objectFit: 'cover',
  };
};

const getPassedSteps = (user: CurrentUser) => {
  const isRawUser = !user?.email;
  const isCompleteUser = user.email && !user.registration_step;
  if (isRawUser) {
    return [];
  }
  if (isCompleteUser) {
    return SIGN_UP_STEP_CAPTIONS;
  }
  const currentStepCaption = SIGN_UP_STEP_CAPTION_MAP[user.registration_step || 'verify-phone'];
  const currentStepIndex = SIGN_UP_STEP_CAPTIONS.indexOf(currentStepCaption);
  const safeStepIndex = currentStepIndex >= 0 ? currentStepIndex : 0;
  return SIGN_UP_STEP_CAPTIONS.slice(0, safeStepIndex);
};

export const getPassedGearSteps = (instance: GearInstance) => {
  const allGearSteps: ItemManagementApi[] = GEAR_CREATION_STEPS[instance?.options?.length ? 'rent' : (instance?.type || 'sell')];
  const prevIndex = instance.step ? allGearSteps.indexOf(instance.step) : 0;
  const currentStepIndex = prevIndex >= 0 ? prevIndex : 0;
  const gearPassedSteps: ItemManagementApi[] = instance.step
    ? ['main-info', ...allGearSteps.slice(0, currentStepIndex)]
    : typeof instance.step === 'undefined'
    ? []
    : allGearSteps;

  const passedStepCaptions = gearPassedSteps.map((gearStep) => (
    instance?.type==='rent'
      ? RENT_GEAR_CREATION_STEP_CAPTION_MAP[gearStep]
      : instance?.options?.length
        ? GEAR_OPTIONS_CREATION_STEP_CAPTION_MAP[gearStep]
        : GEAR_CREATION_STEP_CAPTION_MAP[gearStep]
  ));

  if(instance?.options?.length)
    passedStepCaptions.push('Pricing')

  return [...GEAR_CREATION_BASE_STEP_CAPTIONS, ...passedStepCaptions];
};

export const getSignUpSteps = (user: CurrentUser = {}) => {
  const passed = getPassedSteps(user);

  return {
    passed: passed,
    current: passed.length ? passed.length : 0,
  };
};

export const getAllCustomerOptionData = async (force?: boolean) => {
  const activitiesRequest = getCustomerActivities({}, force);
  const brandsRequest = getCustomerBrands({}, force);
  const categoriesRequest = getCustomerCategories({}, force);
  const marketsRequest = getCustomerMarkets({}, force);
  // const nestedCategoriesRequest = getCustomerCategoriesAsTree();

  return Promise.all([activitiesRequest, brandsRequest, categoriesRequest, marketsRequest]);
};

export const checkDateEqual = (firstDate: Date, secondDate: Date) => {
  return (
    firstDate.getDate() === secondDate.getDate() &&
    firstDate.getMonth() === secondDate.getMonth() &&
    firstDate.getFullYear() === secondDate.getFullYear()
  );
};

export const isDateBlocked = (blocked: Date[], date: Date): boolean => {
  if (!date) return false;
  return !!blocked.find((d) => checkDateEqual(d, date));
};

export const isPastDate = (date: Date): boolean => date < new Date() && !checkDateEqual(date, new Date());

export const isLowerThanControlDate = (date: Date, control: Date): boolean =>
  date < control && !checkDateEqual(date, control);

export const toggleDateInList = (list: Date[], date: Date) => {
  const filteredList = list.filter((d) => !checkDateEqual(d, date));
  if (filteredList.length === list.length) {
    filteredList.push(date);
  }
  return filteredList;
};

export const getTimeInterval = (timestamp?: number) => {
  if (typeof timestamp !== 'number' || timestamp < 1000000000) {
    console.warn('getTimeInterval -> timestamp variable came with wrong value: ', timestamp);
    return 'right now';
  }
  const initTime = timestamp < 1000000000000 ? timestamp * 1000 : timestamp;
  const currentTime = Date.now();
  const difference = currentTime - initTime;

  const minuteDiff = Math.floor(difference / 1000 / 60);
  const hourDiff = Math.floor(difference / 1000 / 60 / 60);
  const dayDiff = Math.floor(difference / 1000 / 60 / 60 / 24);

  if (dayDiff > 1) {
    return `${dayDiff} day${dayDiff > 1 ? 's' : ''} ago`;
  }
  if (hourDiff > 1) {
    return `${hourDiff} hour${hourDiff > 1 ? 's' : ''} ago`;
  }
  if (minuteDiff > 1) {
    return `${minuteDiff} min ago`;
  }
  return 'right now';
};

export const filterValuesThroughOptions = (
  values: Array<string | number>,
  options: Array<DropdownOption>,
): Array<string | number | any> =>
  values.filter((value) => !!options.find((option) => option.id === value || option.value === value));

export const filterOptionsByValues = (
  options: Array<DropdownOption> | any,
  values: Array<string | number> | Array<DropdownOption> | any,
): Array<DropdownOption> =>
  options.filter(
    (option: any) =>
      // @ts-ignore
      !values.find((value: any) => (typeof value === 'object' ? value.id === option.id : value === option.id)),
  );

const chooseConnector = (redirect: string) => (redirect.includes('?') ? '&' : '?');

const removeRedirectFromEntries = (entries: Array<Array<string>>) => entries.filter((entry) => entry[0] !== 'redirect');

const composeQueryEntries = (entries: Array<Array<string>>) => entries.map((entry) => `${entry[0]}=${entry[1]}`);

export const getRedirectUrlWithQuery = (query?: ParsedUrlQuery) => {
  if (!query?.redirect) {
    return '';
  }
  if (Object.keys(query).length === 1) {
    return query.redirect;
  }
  return `${query.redirect}${chooseConnector(query.redirect as string)}${composeQueryEntries(
    removeRedirectFromEntries(Object.entries(query as Record<string, string>)),
  ).join('&')}`;
};

export const asyncGetEmptyObject = async () => ({});

export const toAuthAndBack = (redirect: string, router?: any, user?: CurrentUser) => {
  const registrationIncomplete = checkIfSignUpIncomplete(user);
  const path = `/${registrationIncomplete ? 'sign-up' : 'sign-in'}?redirect=/${redirect}`;
  if (router) {
    return router.push(path);
  } else if (typeof window === 'object') {
    window.location.assign(path);
  }
};

export const goToComment = (slug: string) => {
  window.location.assign(`${window.location.origin}/gear/${slug}?to_comments=1`);
};

export const handleFavorite = async (
  slug: string,
  isFavorite: boolean,
  notificator: Dispatch<SnackbarProps | null>,
) => {
  const response = await postGearData(slug, 'favorite', { is_favorite: !isFavorite });
  if (response.ok) {
    notificator({
      header: 'Favorite status changed',
    });
  } else {
    notificator(composeServerErrorForSnackbar(response));
  }
  return response.ok;
};

export const handleShare = async (slug: string, notificator: Dispatch<SnackbarProps | null>) => {
  const response = await postGearData(slug, 'share');
  if (response.ok) {
    notificator({
      header: 'Gear shared through your network',
    });
  } else {
    notificator(composeServerErrorForSnackbar(response));
  }
  return response.ok;
};

export const getFormattedServersideErrors = (response: ErrorResponse) => {
  let errorHeader = response.error || response.message || 'Sorry, server responds with error. Please, try later';
  const errorDetails = Array.isArray(response.errors)
    ? response.errors
    : Object.entries(response.errors || {}).map(
        (entry) => `${entry[0].toUpperCase()}: ${Array.isArray(entry[1]) ? entry[1].join('\n') : entry[1]}`,
      );

  const sanitizedDetails = errorDetails.map((error) => {
    if (error.startsWith('ADDRESS_ID')) {
      errorHeader = 'Delivery not available';
      console.error('shipping error: ', errorHeader);
      return 'Temporarily it is impossible to order gear from another country';
    }
    return error;
  });

  return {
    header: errorHeader,
    message: sanitizedDetails.join('\n'),
  };
};

export const formatServerErrorsForNotification = (response: ErrorResponse, period?: number) => {
  return {
    mode: 'error',
    period,
    ...getFormattedServersideErrors(response),
  };
};

export const pushSnackbarErrorNotification = (
  response: ErrorResponse,
  callback: Dispatch<SnackbarProps | null>,
  period?: number,
) => callback(formatServerErrorsForNotification(response, period));

export const formatErrorsForFormNotification = (response: ErrorResponse, errorFieldName: string = 'apiError') => {
  return {
    [errorFieldName]: formatServerErrorsForNotification(response),
  };
};

export const getPercentageOptions = (step = 1) =>
  [...Array(Math.round(100 / step) + 1)].map((x, i) => ({ value: i * step, title: `${i * step}%` }));

export const getArrayOfIndexes = (length: number) =>
  Array(length)
    .fill(null)
    .map((_, i) => i);

export const hasActiveShippingLabel = (status: OrderStatus) =>
  ['shipping_waiting'].includes(status); //, 'in_transit', 'delivered'

export const haveReturnShippingLabel = (status: OrderStatus) =>
  ['return_shipping_waiting'].includes(status); //, 'return_in_transit', 'return_delivered', 'returned'

export const hasShippingLabel = (status: OrderStatus) =>
  [
    'shipping_waiting',
    'return_shipping_waiting',
  ].includes(status);

export const getShippingLabelUrl = (id: number | string, status: OrderStatus, isPurchase?: boolean) => {
  if (!isPurchase && hasActiveShippingLabel(status)) {
    return `${process.env.NEXT_PUBLIC_API_BASE_URL}/orders/${id}/shipping-label`;
  }
  if (isPurchase && haveReturnShippingLabel(status)) {
    return `${process.env.NEXT_PUBLIC_API_BASE_URL}/orders/${id}/return-shipping-label`;
  }
  return '';
};

export const getAdminShippingLabelUrl = (id: number | string, type: 'base' | 'return') => {
  return `${process.env.NEXT_PUBLIC_API_BASE_URL}/admin/orders/${id}/shipping-label/${type}`;
};

export const getLinkWithQueryRedirect = (targetLink: string) => {
  // only client side
  if (typeof document !== 'object') {
    return '/';
  }
  if (document.location.search) {
    return `${document.location.origin}/${targetLink}${document.location.search}&redirect=${document.location.pathname}`;
  }
  if (document.location.pathname) {
    return `${document.location.origin}/${targetLink}?redirect=${document.location.pathname}`;
  }
  return `${document.location.origin}/${targetLink}`;
};

export const openDropdownUp = (ref: MutableRefObject<HTMLDivElement>, offset: number = 56) => {
  const elementRect = ref.current?.getBoundingClientRect() || {};
  return !!ref.current && window.innerHeight < elementRect.bottom && elementRect.y > elementRect.height + offset;
};

export const shiftDropdownOptionsRight = (ref: MutableRefObject<HTMLDivElement>, offset: number = 10): boolean => {
  const elementClientRect = ref.current?.getBoundingClientRect() || {};
  return +elementClientRect?.left + 500 + offset < window.innerWidth;
};

export const fulfillDateRange = (startDate: Date, endDate: Date) => {
  const result = [new Date(startDate)];
  while (isLowerThanControlDate(startDate, endDate)) {
    result.push(new Date(startDate.setDate(startDate.getDate() + 1)));
  }
  return result;
};

export const isBlockedDateInDateRange = (firstDate: Date, secondDate: Date, blockedDates: Date[]) => {
  return !!blockedDates.find((date) => {
    return (
      checkDateEqual(date, firstDate) || checkDateEqual(date, secondDate) || (firstDate < date && secondDate > date)
    );
  });
};

export const getTimeHourDifference = (firstTime?: string, secondTime?: string) => {
  if (!secondTime || !firstTime) {
    return 0;
  }
  return +secondTime.split(':')[0] - +firstTime.split(':')[0];
};

export const getNumberHour = (time: string) => {
  return +time.split(':')[0];
};

export const checkTakenTimeInSelected = (
  blockedTimes: UnavailableTimes = {},
  date: string,
  selectedTimes: SpecificCalendarRangeProps,
) => {
  const currentBlockedTimesSet = blockedTimes[date];
  if (!currentBlockedTimesSet) return false;

  const selectedStart = getNumberHour(selectedTimes.startTime || '');
  const selectedEnd = getNumberHour(selectedTimes.endTime || '');

  return currentBlockedTimesSet.reduce((acc, times) => {
    if (acc) return acc;

    const takenStart = getNumberHour(times.from_time);
    const takenEnd = getNumberHour(times.to_time);

    const takenTimeInSelected = selectedStart <= takenStart && takenEnd <= selectedEnd;
    const startTimeInTaken = takenStart < selectedStart && selectedStart < takenEnd;
    const endTimeInTaken = takenStart < selectedEnd && selectedEnd < takenEnd;

    return takenTimeInSelected || startTimeInTaken || endTimeInTaken;
  }, false);
};

export const getDatesWithHourRent = (blockedTimes: UnavailableTimes = {}, selectedDates: Date[]) =>
  selectedDates.reduce((acc, date) => {
    if (blockedTimes[formatDate(date, 'YYYY-MM-DD')]) {
      acc.push(formatDate(date, 'MM/DD/YYYY'));
    }
    return acc;
  }, [] as string[]);

export const sortByTextField = (array: any[], field: string = 'title') => {
  return [...array].sort((a, b) => {
    const nameA = a[field].toUpperCase();
    const nameB = b[field].toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};

export const isItemInOptions = (id: string | number, options: DropdownOption[]) =>
  !!options.find((option) => option.value == id || option.id == id);

export const isItemInNestedOptions = (id: string | number, options: any[]): boolean =>
  options.reduce((acc, option) => {
    if (acc) {
      return acc;
    }
    if (option.value == id || option.id == id) {
      return true;
    }
    if (option.children) {
      return isItemInNestedOptions(id, option.children);
    }
    return acc;
  }, false);

export const checkIsAllItemsInOptions = (items: Array<string | number>, options: DropdownOption[]) => {
  if (!items.length) {
    return false;
  }

  return items.reduce((acc, item) => {
    if (!isItemInOptions(item, options)) {
      return false;
    }
    return acc;
  }, true);
};

export const filterUsedItemsFromOptions = (items: SimpleItem[], options: DropdownOption[]) =>
  options.filter((option) => !items.find((item) => item.id === option.id));

export const searchThroughChildren = (
  query?: string,
  options: DropdownOptionWithChildren[] = [],
  field: string = 'title',
): any[] =>
  options
    .map((option) => {
      if (!query) return option;
      // @ts-ignore
      if (option[field]?.toLowerCase().includes(query.toLowerCase())) return option;
      if (option.children?.length) {
        const filteredChildren = searchThroughChildren(query, option.children, field);
        if (filteredChildren.length) {
          return { ...option, children: filteredChildren };
        }
      }
    })
    .filter((instance) => !!instance);

export const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1)
};

export const isFullNameCoded = (name: string) => name.length > 300;

export const sanitizeFullName = (name: string, alterName?: string) =>
  isFullNameCoded(name) ? alterName || 'Deleted user' : name;

export const getUserFirstAndLastName = (user: CurrentUser) => {
  const first_name = user.first_name || user.full_name?.split(' ')[0] || '';
  const last_name = user.last_name || user.full_name?.split(' ')[1] || '';

  return {
    first_name,
    last_name,
  };
};

export const checkIsAdmin = (user?: CurrentUser | null) => user?.role === 'admin' || user?.role === 'super_admin';

export const checkIsSystemMessage = (message: MessagesMessage) => {
  return message.sender?.full_name === 'system' || !message.sender?.id;
};

export const isServer = () => typeof window !== 'object';

export const getDateFilterOptions = (): DashboardDateFilterOptions[] => {
  const [firstThisMonthDay, lastThisMonthDay] = getThisMonthFirstLastDays();
  const [firstPreMonthDay, lastPreMonthDay] = getLastMonthFirstLastDays();
  const [firstThisWeekDay, lastThisWeekDay] = getThisWeekFirstLastDays();
  const [firstPreWeekDay, lastPreWeekDay] = getLastWeekFirstLastDays();
  const currentYear = new Date().getFullYear();

  return [
    {
      title: `This month: ${formatDate(firstThisMonthDay, 'MON DD')} - ${formatDate(lastThisMonthDay, 'MON DD')}`,
      id: 'month',
    },
    {
      title: `Last month: ${formatDate(firstPreMonthDay, 'MON DD')} - ${formatDate(lastPreMonthDay, 'MON DD')}`,
      id: 'lastMonth',
    },
    {
      title: `This week: ${formatDate(firstThisWeekDay, 'MON DD')} - ${formatDate(lastThisWeekDay, 'MON DD')}`,
      id: 'week',
    },
    {
      title: `Last week: ${formatDate(firstPreWeekDay, 'MON DD')} - ${formatDate(lastPreWeekDay, 'MON DD')}`,
      id: 'lastWeek',
    },
    {
      title: `This year: ${currentYear}`,
      id: 'year',
    },
    {
      title: 'All Dates',
      id: 'all',
    },
    {
      title: 'Custom Range',
      id: 'custom',
    },
  ];
};

export const getFromToDateStrings = (filterOption: DashboardDateFilterOptionId): Array<string | null> | undefined => {
  if (filterOption === 'month') {
    const [firstThisMonthDay, lastThisMonthDay] = getThisMonthFirstLastDays();
    return [formatDate(firstThisMonthDay, 'YYYY-MM-DD'), formatDate(lastThisMonthDay, 'YYYY-MM-DD')];
  }
  if (filterOption === 'lastMonth') {
    const [firstPreMonthDay, lastPreMonthDay] = getLastMonthFirstLastDays();
    return [formatDate(firstPreMonthDay, 'YYYY-MM-DD'), formatDate(lastPreMonthDay, 'YYYY-MM-DD')];
  }
  if (filterOption === 'week') {
    const [firstThisWeekDay, lastThisWeekDay] = getThisWeekFirstLastDays();
    return [formatDate(firstThisWeekDay, 'YYYY-MM-DD'), formatDate(lastThisWeekDay, 'YYYY-MM-DD')];
  }
  if (filterOption === 'lastWeek') {
    const [firstPreWeekDay, lastPreWeekDay] = getLastWeekFirstLastDays();
    return [formatDate(firstPreWeekDay, 'YYYY-MM-DD'), formatDate(lastPreWeekDay, 'YYYY-MM-DD')];
  }
  if (filterOption === 'year') {
    const currentYear = new Date().getFullYear();
    return [`${currentYear}-01-01`, `${currentYear}-12-31`];
  }
  if (filterOption === 'custom') {
    return undefined;
  }
  return [null, null];
};

export const countDownNotification = (args: CountDownArgs) => {
  const { timer, callback, formatter, fieldName, interval = 1, message } = args;
  // todo refactor this - need to handle double calling with different values for one field
  // @ts-ignore
  if (!globalThis[fieldName]) globalThis[fieldName] = {};
  // @ts-ignore
  if (globalThis[fieldName].timer && globalThis[fieldName].timer !== timer) return;

  if (typeof timer === 'number' && !!timer) {
    callback({ [fieldName]: formatter(message, timer) });
    const newTimer = timer - 1;
    // @ts-ignore
    globalThis[fieldName].timer = newTimer;
    setTimeout(() => countDownNotification({ ...args, timer: newTimer }), 1000 * interval);
  } else {
    // @ts-ignore
    delete globalThis[fieldName].timer;
    // @ts-ignore
    callback({ [fieldName]: null });
  }
};

export const checkIfFullyAuthorized = (user?: CurrentUser | null) =>
  !!user?.email && !user.registration_step && !!user.tac_accepted_at;

export const checkIfSignUpIncomplete = (user?: CurrentUser | null) =>
  !!user?.id && (user.registration_step || !user.tac_accepted_at);

export const handleCopyToClipboard = (value: string) => {
  navigator.clipboard.writeText(value);
};

export const getLinkForGearShare = (hostname: string, slug: string) => {
  return `http${hostname !== HOSTNAMES.LOCALHOST ? 's' : ''}://${
    hostname === HOSTNAMES.LOCALHOST ? HOSTNAMES.LOCALHOST3000 : hostname
  }/gear/${slug}`;
};

export const goTo = (router: any, path: string, newPage?: boolean, callback?: () => void) => {
  callback && callback();
  router ? router.push(path) : window.open(path, newPage ? '_blank' : '_self');
};

export const debounce = (func: Function, timeout = 400) => {
  let timer: ReturnType<typeof setTimeout>;
  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, timeout);
  };
};

export const isParticularGearOwner = (owner: Partial<CustomerProfile>, user?: CurrentUser) => {
  return owner.id === user?.id;
};

export const openMobileApp = (link: string) => {
  if (typeof window !== 'undefined' && !navigator?.userAgent?.includes('Safari')) {
    window.location.assign(`sendy://${link}`);
  }
};

// only for client side usage
export const handleLoginSocial = async (token: string, redirect?: string) => {
  const response = await authSocial({ token });
  const registrationIncomplete = response.data && response.data.registration_step;
  const tosUnaccepted = response.data && !response.data.tac_accepted_at;
  if (response.ok && (registrationIncomplete || tosUnaccepted)) {
    window.location.assign(`/sign-up${redirect ? '?redirect=' + redirect : ''}`);
    return;
  }
  if (!response.ok) {
    const { header, message } = formatServerErrorsForNotification(response);
    saveSessionError(header, message);
  }
  trackSessionRedirects(null, () => {
    window.location.assign(redirect || '/');
  });
};

export const getAfterLogInRedirectPath = (query?: ParsedUrlQuery) => {
  if (query?.redirect) {
    return String(query?.redirect);
  }
  return '/feed';
};

export const prepareRedirectPath = () => {
  const path = window.location.pathname;
  if (path === '/') {
    return '';
  }
  return `?redirect=${path}`;
};

export const saveSessionRedirect = (path?: string) => {
  if (typeof window !== 'object') return;
  sessionStorage.setItem('redirect', path || window.location.pathname);
};

export const getSafeArrayFromObject = (source: Record<string, Array<any> | any>, fields: string[] = []) => {
  return fields.map((field: string) => {
    if (Array.isArray(source[field])) {
      return source[field];
    } else {
      return [];
    }
  });
};

export const fetchRegularDataForItemManagement = async (force?: boolean, cookies?: Cookies) => {
  const activitiesRequest = getCustomerActivities({ has_categories: 1, has_markets: 1 }, force);
  const brandsRequest = getCustomerBrands({ with_user: 1 }, force);
  const categoriesRequest = getCustomerCategories({}, force);
  const marketsRequest = getCustomerMarkets({}, force);
  const colorsRequest = getCustomerColors({}, force);
  const dimensionSetRequest = getCustomerShippingDimensions(force, cookies);

  const [activities, brands, categories, markets, colors, dimensionSets] = await Promise.all([
    activitiesRequest,
    brandsRequest,
    categoriesRequest,
    marketsRequest,
    colorsRequest,
    dimensionSetRequest,
  ]);

  return {
    activities,
    brands,
    categories,
    colors,
    dimensionSets,
    markets,
  };
};

export const serverSideCacheWrapper = async (args: CachePickDataArgs) => {
  const { callback, key, params = [], force } = args;
  if (typeof window === 'object') {
    return await callback(...params);
  }
  return await globalThis.cacheProvider.pickData({
    key: key,
    callback: callback,
    params,
    force,
  });
};

export const sanitizeServerNumber = (value: string | number): number => {
  if (typeof value === 'number') {
    return isNaN(value) ? 1 : value;
  }
  return +value.replaceAll(',', '');
};

export const isSystemChat = (type?: MessagesRoomType) => SYSTEM_CHAT_TYPES.includes(type || ('' as MessagesRoomType));

export const putFieldConditionally = (
  condition: boolean,
  field: JoinedFormFieldProps | Record<string, any>,
): JoinedFormFieldProps => {
  if (condition) {
    return field;
  }

  const emptyField = { type: 'null' };

  return Array.isArray(field) ? [] : emptyField;
};

export const checkIsProduction = () => {
  return process.env.NODE_ENV === 'production' && process.env.NEXT_PUBLIC_API_HOST === 'https://api.sendy.io';
};

export const statusBackgroundColor = {
  expired: '#F7FAFC',
  canceled: '#F7FAFC',
  declined: '#F7FAFC',
  pending: '#EFC1481A',
  accepted: '#6CC4D430',
}

export const statusColor = {
  expired: '#8C979F',
  canceled: '#8C979F',
  declined: '#8C979F',
  pending:  '#EFC148',
  accepted: '#6CC4D4',
}

export const statusIcon = {
  expired: "/icons/orderStatus/Declined.svg",
  canceled: "/icons/orderStatus/Declined.svg",
  declined: "/icons/orderStatus/Declined.svg",
  pending: "/icons/orderStatus/Waiting.svg",
  accepted:  "/icons/orderStatus/Success.svg",
}

export const statusTitle = {
  expired: 'Expired',
  declined: 'Declined',
  canceled: 'Canceled',
  pending: 'Waiting response',
  accepted: 'Accepted'
}

import {useEffect, useState} from "react";

const useCheckMobileScreen = () => {
  const [width, setWidth] = useState(700);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  return (width <= 1270);
}

export default useCheckMobileScreen
